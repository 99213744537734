import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';


const HostingPrices = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [description, setDescription] = useState('We are processing your page content.');
const [tags, setTags] = useState('Processing, Waiting');
const [image, setImage] = useState('/static/waiting.jpg');

useEffect(() => {
API.get(`api/pages/get-domains/`)
.then((res) => {
setTitle(res.data.title);
setDescription(res.data.description);
setTags(res.data.tags);
setImage(res.data.image);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setDescription('Server did not respond.');
setTags('Error');
setImage('/static/error.jpg');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
<meta name="description" content={ description }/>
<meta name="keywords" content={ tags }/>
<meta property="og:image" content={ image } />
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{!error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify marmasco-padding">
<h4 className="marmasco-text-teal"><b>HOSTING PRICES<i className="fas fa-tag marmasco-right"></i></b></h4>
<div style={{width: "100%", overflowX: "auto"}}>
<table class="marmasco-table marmasco-bordered" style={{border: "solid grey 1px" }}>
<tr className="marmasco-sand">
  <th>Package</th>
  <th>Storage</th>
  <th>Domains</th>
  <th>Subdomains</th>
  <th>Monthly</th>
  <th>Subscribe</th>
</tr>
<tr className="marmasco-hover-teal">
  <td>Personal</td>
  <td>6 GB</td>
  <td>1</td>
  <td>6</td>
  <td>2.00 USD</td>
  <td className="marmasco-btn marmasco-hover-sand marmasco-center marmasco-pale-green">BUY</td>
</tr>
<tr className="marmasco-hover-teal">
  <td>Business</td>
  <td>10 GB</td>
  <td>1</td>
  <td>10</td>
  <td>4.00 USD</td>
  <td className="marmasco-btn marmasco-hover-sand marmasco-center marmasco-pale-green">BUY</td>
</tr>
</table>
</div>
</div>
<div className="marmasco-container marmasco-content marmasco-padding" >
  <div style={{paddingTop: "50px"}}></div>
  <SearchDomain />
  <div style={{paddingTop: "50px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default HostingPrices;