import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';

const VpsHosting = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [description, setDescription] = useState('We are processing your page content.');
const [tags, setTags] = useState('Processing, Waiting');
const [image, setImage] = useState('/static/waiting.jpg');

useEffect(() => {
API.get(`api/pages/get-domains/`)
.then((res) => {
setTitle(res.data.title);
setDescription(res.data.description);
setTags(res.data.tags);
setImage(res.data.image);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setDescription('Server did not respond.');
setTags('Error');
setImage('/static/error.jpg');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
<meta name="description" content={ description }/>
<meta name="keywords" content={ tags }/>
<meta property="og:image" content={ image } />
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{!error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify">
<h4 className="marmasco-text-teal"><b>VPS HOSTING<i className="fas fa-circle-info marmasco-right"></i></b></h4>
<p>Web hosting is a service that Marmasco provides to its customers to enable accessibility of their websites over World Wide Web. We power both static and dynamic webistes through our servers. You can host CMS like WordPress on our servers and experience amazing perfomance.</p>
<p>You can host your website on our shared platform. This is a very cheap and effective way to jump-start your business.</p>
<p>As your business grows, your needs may out grow our shared hosting packages, hence we give you a VPS dedicated to your website alone with a dedicated IP address and your own customised software. Qualified partners can resale our hosting services and all domain extensions.</p>

</div>
<div className="marmasco-container marmasco-content marmasco-padding" >
  <div style={{paddingTop: "50px"}}></div>
  <SearchDomain />
  <div style={{paddingTop: "50px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default VpsHosting;