import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';

const GetDomains = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [description, setDescription] = useState('We are processing your page content.');
const [tags, setTags] = useState('Processing, Waiting');
const [image, setImage] = useState('/static/waiting.jpg');

useEffect(() => {
API.get(`api/pages/get-domains/`)
.then((res) => {
setTitle(res.data.title);
setDescription(res.data.description);
setTags(res.data.tags);
setImage(res.data.image);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setDescription('Server did not respond.');
setTags('Error');
setImage('/static/error.jpg');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
<meta name="description" content={ description }/>
<meta name="keywords" content={ tags }/>
<meta property="og:image" content={ image } />
</Helmet>

{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{!error
? <div className="marmasco-container">
  <Error />
  </div>
: <>
<div className="marmasco-container marmasco-content marmasco-justify">
<h4 className="marmasco-text-teal"><b>DOMAIN REGISTRATION<i className="fas fa-circle-info marmasco-right"></i></b></h4>
<p>A domain name is your identity and a special address on the internet where your website and emails can be reached. A valid domain name has an identifier and an extension, that is domainname.tld where domain is an identifier and tld is an extension. You can register hundreds of domain extensions through Marmasco.</p>
<p>When registering or transferring domains in Zimbabwe all details must belong to the actual owner otherwise domains registered with false information will be deleted.</p>
<p>All domains, either local or international extensions, registered through Marmasco must have valid nameservers.</p>

</div>
<div className="marmasco-container marmasco-content marmasco-padding" >
  <div style={{paddingTop: "50px"}}></div>
  <SearchDomain />
  <div style={{paddingTop: "50px"}}></div>
  <ReadMore />
  </div>
  </>
}
  </div>
}
<Top />
</div>
);}

export default GetDomains;