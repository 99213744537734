export default function Greeting() {
var myDate = new Date();
var hours= myDate.getHours();
var greet;
if (hours < 12)
greet =  "GOOD MORNING";
else if (hours >= 12 && hours <= 17)
greet = "GOOD AFTERNOON";
else if (hours >= 18 && hours <= 20)
greet = "GOOD EVENING";
else if (hours >= 21 && hours <= 24)
greet = "GOOD NIGHT";

return greet;
}