import React from 'react';

function Error() {

return (
<div className="marmasco-content marmasco-center">
<br />
<h4>Sorry, we encountered an error while processing!</h4>
<br />
<p><i className="fas fa-exclamation-triangle fa-7x marmasco-color"></i></p>
</div>
);}

export default Error;