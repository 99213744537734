import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../web/templates/header";
import Footer from "../web/templates/footer";
import Home from "../web/pages/home";
import NotFound from "../web/pages/404";
import GetDomains from "../web/pages/getDomains";
import DomainsFeatures from "../web/pages/domainsFeatures";
import DomainsPrices from "../web/pages/domainsPrices";
import WebHosting from "../web/pages/webHosting";
import HostingFeatures from "../web/pages/hostingFeatures";
import HostingPrices from "../web/pages/hostingPrices";
import EmailFeatures from "../web/pages/emailFeatures";
import EmailHosting from "../web/pages/emailHosting";
import EmailPrices from "../web/pages/emailPrices";
import VpsHosting from "../web/pages/vpsHosting";
import VpsFeatures from "../web/pages/vpsFeatures";
import VpsPrices from "../web/pages/vpsPrices";
import ResHosting from "../web/pages/resHosting";
import ResFeatures from "../web/pages/resFeatures";
import ResPrices from "../web/pages/resPrices";
import Blog from "../web/pages/blog";
import Login from "../web/pages/login";
import Reset from "../web/pages/reset";
import Recover from "../web/pages/recover";
import Register from "../web/pages/register";
import Account from "../web/pages/account";
import Admin from "../web/pages/admin";
import Cart from "../web/pages/cart";
import About from "../web/pages/about";
import Terms from "../web/pages/terms";
import Privacy from "../web/pages/privacy";
import Contacts from "../web/pages/contacts";
import Support from "../web/pages/support";

class App extends Component {
render() {
return (
<Router>
<Header />
<Routes>
<Route exact path="/" element={<Home/>} />
<Route exact path="/get-domains/" element={<GetDomains/>} />
<Route exact path="/get-domains/features/" element={<DomainsFeatures/>} />
<Route exact path="/get-domains/pricing/" element={<DomainsPrices/>} />
<Route exact path="/web-hosting/" element={<WebHosting/>} />
<Route exact path="/web-hosting/features/" element={<HostingFeatures/>} />
<Route exact path="/web-hosting/pricing/" element={<HostingPrices/>} />
<Route exact path="/email-hosting/" element={<EmailHosting/>} />
<Route exact path="/email-hosting/features/" element={<EmailFeatures/>} />
<Route exact path="/email-hosting/pricing/" element={<EmailPrices/>} />
<Route exact path="/vps-hosting/" element={<VpsHosting/>} />
<Route exact path="/vps-hosting/features/" element={<VpsFeatures/>} />
<Route exact path="/vps-hosting/pricing/" element={<VpsPrices/>} />
<Route exact path="/reseller-hosting/" element={<ResHosting/>} />
<Route exact path="/reseller-hosting/features/" element={<ResFeatures/>} />
<Route exact path="/reseller-hosting/pricing/" element={<ResPrices/>} />
<Route exact path="/blog/" element={<Blog/>} />
<Route exact path="/auth/register/" element={<Register/>} />
<Route exact path="/auth/login/" element={<Login/>} />
<Route exact path="/auth/reset/" element={<Reset/>} />
<Route exact path="/auth/recover/" element={<Recover/>} />
<Route exact path="/account/" element={<Account/>} />
<Route exact path="/admin/" element={<Admin/>} />
<Route exact path="/cart/" element={<Cart/>} />
<Route exact path="/about/" element={<About/>} />
<Route exact path="/terms/" element={<Terms/>} />
<Route exact path="/privacy/" element={<Privacy/>} />
<Route exact path="/contacts/" element={<Contacts/>} />
<Route exact path="/support/" element={<Support/>} />
<Route path="*" element={<NotFound/>} />
</Routes>
<Footer />
</Router>
);}}
 
export default App;