import React from 'react';
import { Link } from "react-router-dom";

function ReadMore() {

return (
<div className="marmasco-row-padding">
<h4 className="marmasco-text-teal"><b><Link to="/blog/" style={{textDecoration: "none"}}>Read More</Link><i className="fas fa-book marmasco-right"></i></b></h4>
</div>
);}

export default ReadMore;