import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
const [visible, setVisible] = useState(false);

const toggleVisible = () => {
const scrolled = document.documentElement.scrollTop;
if (scrolled > 80){
setVisible(true)
} else if (scrolled <= 80){
setVisible(false)
}};
const scrollToTop = () =>{
window.scrollTo({
top: 0, 
behavior: 'smooth'
});};
window.addEventListener('scroll', toggleVisible);

return (
<div className="marmasco-main put-margin">
<footer className="marmasco-container marmasco-light-grey marmasco-center foot">
<div className="marmasco-content marmasco-row-padding">
<div className="marmasco-col s6">
<div className="marmasco-third">
<p><Link to={"/about/"}>About</Link></p>
</div>
<div className="marmasco-third">
<p><Link to={"/privacy/"}>Privacy</Link></p>
</div>
<div className="marmasco-third">
<p><Link to={"/terms/"}>Terms</Link></p>
</div>
</div>
<div className="marmasco-col s6">
<div className="marmasco-third">
<p><Link to={"/blog/"}>Blog</Link></p>
</div>
<div className="marmasco-third">
<p><Link to={"/support/"}>Support</Link></p>
</div>
<div className="marmasco-third">
<p><Link to={"/contacts/"}>Contacts</Link></p>
</div>
</div>
</div>
<br />
<p>&copy;2019 - {new Date().getFullYear()} Marmasco Technologies P/L - All Rights Reserved</p>
<button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} className="marmasco-scroll-up marmasco-teal marmasco-btn" title="Scroll Up"><i className="fas fa-arrow-up"></i></button>
</footer>
</div>
)};

export default Footer;