import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Top from '../hooks/topping';
import SearchDomain from '../components/searchDomain';
import ReadMore from '../components/readMore';

function ResFeatures() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('Please Wait');
    const [description, setDescription] = useState('We are processing your page content.');
    const [tags, setTags] = useState('Processing, Waiting');
    const [image, setImage] = useState('/static/waiting.jpg');

    useEffect(() => {
        API.get(`api/pages/get-domains/`)
            .then((res) => {
                setTitle(res.data.title);
                setDescription(res.data.description);
                setTags(res.data.tags);
                setImage(res.data.image);
                setLoading(false);
            })
            .catch((error) => {
                setTitle('Not Responding');
                setDescription('Server did not respond.');
                setTags('Error');
                setImage('/static/error.jpg');
                setLoading(false);
                setError(true);
            });
    }, []);

    return (
        <div className="marmasco-main put-margin inner">
            <Helmet>
                <title>{title} - Marmasco</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={tags} />
                <meta property="og:image" content={image} />
            </Helmet>

            {loading
                ? <div className="marmasco-section">
                    <div className="marmasco-container">
                        <Loader />
                    </div>
                </div>
                : <div className="marmasco-section">
                    {!error
                        ? <div className="marmasco-container">
                            <Error />
                        </div>
                        : <>
                            <div className="marmasco-container marmasco-content marmasco-justify marmasco-padding">
                            <h4 className="marmasco-text-teal"><b>RESELLER FEATURES<i className="fas fa-magic marmasco-right"></i></b></h4>
<p>Web Hosting in Zimbabwe has become a common business for many, but what Marmasco different and outstanding? Well, we have amazing features and reliability that ensures growth for our customers and a peace of mind.</p>
<p>We run state of the art servers powered with high perfoming SSD storage. Solid State Drivers used by Marmasco for Web Hosting can guarantee speed and less failures thus making uptime stay at around 99.9%. We do not just give you high perfoming server but we also make sure you get a bigger storage space at a small price.</p>
<p>Hosting your website with Marmasco gives you access to our fast connection and unlimited bandwidth. We support websites with high traffic volumes without ending up throttling bandwidth. If you are planning to host an online shop, Marmasco can support you at a very affordable price.</p>                         
<p>We offer a modern and friendly control panel that allows you to manage your websites on the go. Install WordPress and any other popular Content Management System with just a few clicks from any device. Our control panel is not confusing, only the features you actually need are available in your account, no need to spend your time trying to understand what everthing else do while you do not pratically use them.</p>                        
<p>Security in this day is a major worry, you need to seriouly consider it before you hit Signup on any website. Marmasco offers free SSL certificates using Lets'sEncrypt on all accounts and hosted domains. We also work hard to ensure all our servers are up to date and protected from intrusion.</p>                        
                         </div>
                            <div className="marmasco-container marmasco-content marmasco-padding">
                                <div style={{ paddingTop: "50px" }}></div>
                                <SearchDomain />
                                <div style={{ paddingTop: "50px" }}></div>
                                <ReadMore />
                            </div>
                            
                        </>}
                </div>}
            <Top />
        </div>
    );
}

export default ResFeatures;