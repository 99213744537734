import React from 'react';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

function Loader() {
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #009688;`;

return (
<div className="marmasco-content marmasco-center">
<h1>Loading...</h1>
<br />
<ClipLoader css={override} size={150} />
</div>
);}

export default Loader;