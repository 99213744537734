import React from 'react';

function SearchDomain() {

return (
<form className="marmasco-white marmasco-container marmasco-padding marmasco-border marmasco-border-grey" style={{borderRadius: "10px"}}>
<div className="marmasco-col s7">
<input type="text" name="domain" className="marmasco-input marmasco-border-0" style={{borderRadius: "10px 0 0 10px", width: "100%", outline: "none"}} placeholder="domain e.g company.co.zw"  required/>
</div>
<div className="marmasco-col s5">
<button type="submit" name="search" className="marmasco-btn marmasco-teal marmasco-border-0" style={{borderRadius: "10px", width: "100%"}}><i className="fas fa-globe"></i> SEARCH</button>
</div>
</form>
);}

export default SearchDomain;