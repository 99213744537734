import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../components/loader';
import Error from '../components/error';
import API from '../../jsx/backend';
import Logger from '../sections/login';
import Top from '../hooks/topping';

const Login = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [description, setDescription] = useState('We are processing content.');
const [tags, setTags] = useState('Processing, Waiting');
const [image, setImage] = useState('/static/waiting.jpg');

useEffect(() => {
API.get(`api/pages/account/login/`)
.then((res) => {
setTitle(res.data.title);
setDescription(res.data.description);
setTags(res.data.tags);
setImage(res.data.image);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setDescription('Server did not respond.');
setTags('Error');
setImage('/static/error.jpg');
setLoading(false);
setError(true);
})}, []);

return (
<div className="marmasco-main put-margin inner">
<Helmet>
<title>{ title } - Marmasco</title>
<meta name="description" content={ description }/>
<meta name="keywords" content={ tags }/>
<meta property="og:image" content={ image } />
</Helmet>
{loading
? <div className="marmasco-section">
  <div className="marmasco-container">
  <Loader />
  </div>
  </div>
: <div className="marmasco-section">
{!error
? <div className="marmasco-container">
  <Error />
  </div>
: <div className="marmasco-container">
  <Logger />
  </div>
}
  </div>
}
<Top />
</div>
);}

export default Login;