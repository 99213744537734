import React, { useState } from 'react';
import Loader from '../components/loader';
import API from '../../jsx/backend';
import { Link } from "react-router-dom";

const Recova = () => {
const [loading, setLoading] = useState(false);
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');

const handleLogin = (e) => {
e.preventDefault();
const loginFormDataNew = new FormData();
loginFormDataNew.append("email",email);
loginFormDataNew.append("password",password);

API.post(`api/account/login/`, loginFormDataNew)
.then((response) => {

sessionStorage.setItem("token", response.data.token);
setLoading(false);
})
.catch((error) => {

setLoading(false);
})}

return(
<>
{loading
? <Loader />
: <div className="marmasco-content put-center">
  <div className="marmasco-padding put-center-data marmasco-white marmasco-card">
  <form onSubmit={handleLogin}>
  <div className="marmasco-container">
  <h4>LOGIN<i className="fas fa-user-lock marmasco-right"></i></h4>
  <br />
  <label for="email" className="marmasco-left">EMAIL</label>
  <input type="email" id="email" className="marmasco-input marmasco-border" style={{width: "100%", borderRadius: "10px" }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your login email..." required />
  <br />
  <label for="password" className="marmasco-left">PASSWORD</label>
  <input type="password" id="password" className="marmasco-input marmasco-border" style={{width: "100%", borderRadius: "10px" }} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Your login password..." required />
  <br />
  <input type="submit" className="marmasco-input marmasco-button marmasco-teal marmasco-border-0" style={{width: "100%", borderRadius: "10px" }} value="LOGIN NOW" />
  <br />
  </div>
  <div className="marmasco-container">
  <div className="marmasco-content">
  <div className="marmasco-half">
  <div className="marmasco-padding">
  <Link to="/auth/register/"><button type="button" className="marmasco-tiny marmasco-button marmasco-pale-green" style={{width: "100%" }}>NEW ACCOUNT</button></Link>
  </div>
  </div>
  <div className="marmasco-half">
  <div className="marmasco-padding">
  <Link to="/auth/reset/"><button type="button" className="marmasco-tiny marmasco-button marmasco-sand" style={{width: "100%" }}>SET PASSWORD</button></Link>
  </div>
  </div>
  </div>
  </div>
  </form>
  </div>
  </div>
}
</>
);}

export default Recova;